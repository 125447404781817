<template>
  <div>
    <!-- ส่วนข้อมูลหมอ -->
    <DocHeader v-if="type === '1'" />
    <InsuranceHeader v-if="type === '6'" />
    <UserHeader v-if="type != '6' && type != '1'" />
    <!-- ----------- -->
    <b-card>
      <b-row>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <!-- <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group> -->
        </b-col>
        <b-col
          md="4"
          sm="8"
          class="my-1"
        >
          <!-- <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    -- none --
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group> -->
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="12"
        >
          <b-table
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            selectable
            :select-mode="selectMode"
            head-variant="dark"
            @filtered="onFiltered"
            @row-selected="onRowSelected"
          >
            <!-- Status -->
            <!-- <template #cell(FULLNAME)="row"> -->
            <!-- <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge> -->
            <!-- {{ items[row.index].ttl }}{{ items[row.index].name }} {{ items[row.index].lName }}
            </template> -->
            <!-- ปุ่มดูแฟ้ม -->
            <template #cell(expireDate)="data">
              <b-badge :variant="status[1][3]">
                <feather-icon
                  icon="AlertTriangleIcon"
                  class="mr-25"
                />
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(fullname)="data">
              <b-badge
                variant="light-dark"
                class="badge-glow"
              >
                <feather-icon
                  icon="UserIcon"
                  class="mr-25"
                />
                <span>{{ data.item.ttl }} {{ data.item.name }} {{ data.item.lName }}</span>
              </b-badge>
            </template>
            <template #cell(hn)="data">
              <b-badge
                variant="light-dark"
                class="badge-glow"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-25"
                />
                <span>{{ data.item.hn }}</span>
              </b-badge>
            </template>
            <template #cell(display)="row">
              <b-button
                size="sm"
                :variant="buttonVariant(row.item)"
                :disabled="buttonDisable(row.item)"
                @click="info(row.item, row.index, $event.target)"
              >
                {{ textButton }}
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
      <!-- <p>
        Selected Rows:<br>
        {{ selected1 }}
      </p>
      <p>
        Selected Rows1:<br>
        {{ selected }}
      </p> -->
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BRow, BBadge, BCol, BFormGroup, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import DocHeader from '../components/DocHeader.vue'
import InsuranceHeader from '../components/InsuranceHeader.vue'
import UserHeader from '../components/UserHeader.vue'

export default {
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    // DocHeader,
    DocHeader,
    InsuranceHeader,
    UserHeader,
  },
  data() {
    return {
      selected: [],
      selected1: [],
      items2: [],
      dataName: true,
      disable: true,
      textButton: 'ดูแฟ้ม',
      selectMode: 'single',
      headVariant: 'light',
      perPage: 20,
      pageOptions: [3, 5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        'fullname',
        // 'FULLNAME',
        {
          key: 'hn', label: 'HN',
        },
        {
          key: 'expireDate', label: 'expireDate',
        },
        'display',
      ],
      username: '',
      type: '',
      fullName: '',
      items: [],
      status: [{
        5: 'เกินข้อผิดพลาด', 1: 'อนุมัติ', 3: 'หมดอายุ', 2: 'ใกล้หมดอายุ', 4: 'รอดำเนินการ',
      },
      {
        5: 'light-primary', 1: 'light-success', 3: 'light-danger', 2: 'light-warning', 4: 'light-info',
      }],
      keepId: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    // foo() {
    //   return this.dataName ? 'success' : 'warning' // <--- define condition/s
    // },
  },
  mounted() {
    // Set the initial number of items
    this.username = JSON.parse(localStorage.getItem('userData')).username
    this.type = JSON.parse(localStorage.getItem('userData')).type
    this.fullName = JSON.parse(localStorage.getItem('userData')).fullName
    this.totalRows = this.items.length
  },
  async created() {
    // console.log(JSON.parse(localStorage.getItem('userData')))
    this.keepId = JSON.parse(localStorage.getItem('userData')).id
    await this.$http({
      url: `api/HIE/getPatinet/${this.keepId}`,
      method: 'get',
      data: {
      },
    }).then(response => {
      // console.log(response.data)
      this.items = response.data
    })
    await this.totalRow()
    await this.substrRequire_hie()
    // // console.log('this.items', this.items)
  },
  methods: {
    // buttonDisable(item, index) {
    // // console.log('buttonDisable', item.status, index)
    substrRequire_hie() {
      // // console.log('this.items2.length', this.items2.length)
      let index = 0
      for (index; index < this.items.length; index += 1) {
        this.items[index].expireDate = this.items[index].expireDate.substring(0, 10)
      }
    },
    buttonDisable(item) {
      switch (item.status) {
        case 1:
          return false
        case 3:
          return true
        case 4:
          return true
        case 5:
          return true
        default:
          return false
      }
    },
    // buttonVariant(item, index) {
    // // console.log('buttonVariant', item.status, index)
    buttonVariant(item) {
      switch (item.status) {
        case 1: // อนุมัติ
          return 'success'
        case 3: // หมดอายุ
          return 'light'
        case 4: // รอดำเนินการ
          return 'light'
        case 5: // เกินข้อผิดพลาด
          return 'light'
        default:// ใกล้หมดอายุ
          return 'success'
      }
    },
    onRowSelected(items) {
      this.selected = items
    },
    async info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      // console.log('item, index', item, index)
      this.selected1 = item
      this.dataName = false
      // const routeData =
      await this.sendViewer()
      // window.open(routeData.href, '_blank')
      // @click="callMedicine(tr)
      //     callMedicine(collect){
      // // // console.log(collect);
      // this.$store.dispatch('findPdetail', collect)
      // this.$router.push({
      //     name: "viewer",
      //     params: collect
      //   })
    },
    sendViewer() {
      const patientData = this.selected1
      this.$router.push({ name: 'medicalViewer', params: patientData })
    },
    totalRow() {
      this.totalRows = this.items.length
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
// ^[a-zA-Z\s]+$
</script>
