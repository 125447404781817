<template>
  <div>
    <b-row align-h="start">
      <b-col
        cols="5"
        class="cardHie ml-1 pl-1 mb-1"
      >
        <b-row>
          <b-col cols="4">
            <b-img
              class="imgDoc ml-3 pt-2 pb-1"
              rounded="sm"
              :src="patientpic"
            />
          </b-col>
          <b-col
            cols="8"
            class="pt-2 pb-1"
          >
            <h5 class="text-primary">
              <feather-icon
                icon="FileMinusIcon"
                class="mr-75"
              />ข้อมูลแพทย์ผู้ขอดูแฟ้ม</h5>
            <hr>
            <table class="mt-xl-0 w-100 ">
              <!-- <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="HashIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">DoctorName</span>
                </th>
                <th class="pb-50">
                  {{ fullNameDoc }}
                </th>
              </tr> -->
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">DoctorName</span>
                </th>
                <th class="pb-50">
                  {{ fullName }}
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UsersIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Username</span>
                </th>
                <th class="pb-50">
                  {{ username }}
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Type</span>
                </th>
                <th class="pb-50">
                  <b-badge :variant="type1[1][type]">
                    {{ type1[0][type] }}
                  </b-badge>
                </th>
              </tr>
              <!-- <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CreditCardIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">SriphatCode</span>
                </th>
                <th class="pb-50">
                  {{ codeDoc }}
                </th>
              </tr> -->
              <!-- <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="AtSignIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">วิชาชีพเวชกรรมเลขที่</span>
                </th>
                <th class="pb-50">
                  {{ docLicense }}
                </th>
              </tr> -->
            </table>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="5" />
    </b-row>
  </div>
</template>
<script>
import {
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  data() {
    return {
      patientpic: '',
      InfoDoc: [],
      fullNameDoc: '',
      codeDoc: '',
      docLicense: '',
      username: '',
      type: '',
      fullName: '',
      type1: [{
        5: 'ผู้ป่วย', 1: 'แพทย์', 3: 'พนักงานสวนดอก', 2: 'พนักงานศูนย์ศรีพัฒน์', 4: 'ญาติผู้ป่วย', 6: 'บริษัทประกัน', 9: 'ผู้ดูแลระบบ',
      },
      {
        5: 'light-primary', 1: 'light-success', 6: 'light-dark', 2: 'light-warning', 3: 'light-info', 9: 'light-danger', 4: 'light-secondary',
      }],
    }
  },
  async created() {
    // this.getDocInfo()
    this.username = JSON.parse(localStorage.getItem('userData')).username
    this.type = JSON.parse(localStorage.getItem('userData')).type
    this.fullName = JSON.parse(localStorage.getItem('userData')).fullName
    await this.getDocPic()
  },
  methods: {
    async getDocPic() {
      // console.log('ddddddddddddd', this.username)
      await this.$http({
        url: `http://192.168.100.221/api/doctor/${this.username}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        if (response.data == null) {
          this.patientpic = ''
        } else {
          this.patientpic = `data:image/png;base64,${response.data}`
        }
      })
    },
    async getDocInfo() {
      await this.$http({
        url: 'api/Doctor/DoctorsAndClinic',
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        this.InfoDoc = response.data.allDoc
      })
      await this.docName()
    },
    docName() {
      let index = 0
      for (index; index < this.InfoDoc.length; index += 1) {
        if (this.InfoDoc[index].doctorCode === 'OT88') {
          // // console.log(this.InfoDoc[index])
          this.fullNameDoc = `${this.InfoDoc[index].title} ${this.InfoDoc[index].fName} ${this.InfoDoc[index].lName}`
          this.codeDoc = `${this.InfoDoc[index].doctorCode}`
          this.docLicense = `${this.InfoDoc[index].docLicense}`
          break
        // eslint-disable-next-line no-empty
        } else {
        }
      }
    },
  },
}
</script>

<style>
.imgDoc{
  max-width: 65%;
  height: auto;
}
.the_text {
  color: #000000;
  font-weight: normal;
}
.the_textH6 {
  /* color: #6157d6;rgb(0 0 0) */
  color: rgb(0 0 0);
  font-weight: normal;
}
.cardHie {
  border-radius: 10px;
  border: 1px solid rgb(241, 241, 241);
  background-color: rgb(255, 255, 255) !important;
}
</style>
